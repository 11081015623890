import React from 'react';
import './ReviewsStyle.css'

interface ReviewItemProps {
  name: string;
  comment: string;
  createdAt?: string
}

const ReviewItem: React.FC<ReviewItemProps> = ({ name, comment }) => {
  return (
    <>
      <div className="col-xl-5 col-lg-7 col-md-10 col-12">
      </div>

      <div className="col-xl-5 col-lg-7 col-md-10 col-12">
        <div className="d-flex justify-content-between">
          <div className="review-content">
            <p className="fw-bold">{name}</p>
          </div>
        </div>
      </div>

      <div className="col-xl-5 col-lg-7 col-md-10 col-12">
        <div className="review-content mt-2">
          <p>{comment}</p>
        </div>
      </div>

      <div className="col-xl-5 col-lg-7 col-md-10 col-12">
        <hr className="hr-tag" />
      </div>

    </>
  );
};

export default ReviewItem