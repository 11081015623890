import { FC } from 'react'
import { SearchCardProps } from '../../helpers/Props'
import { FormatArabicDate, blogImgUrl, handleImageLinkDrage } from '../../helpers/Helpers'
import Icons from '../../helpers/IconsHelper'
import './SearchPostsCard.css'
import { Link } from 'react-router-dom'

const SearchPostsCard: FC<SearchCardProps> = ({ item }) => {

    return (
        <div className="col-md-6 col-lg-6 col-xl-4 col-xxl-3 col-sm-6">
            <div className='container mb-5'>

                <div className="row">
                    <div className="col-12">
                        <div className="search-post-image o-hidden radius-10px c-pointer">
                            <Link to={`/post/${item.slug}`} state={item} onDragStart={handleImageLinkDrage} className='nav-link w-100 h-100'>
                                <img src={`${blogImgUrl}/${item.image}`} alt={item.title} className='search-post-img radius-10px' />
                            </Link>
                        </div>
                    </div>

                    <div className="col-12 mt-2">
                        <p className='clr-dark title-hover text-end'>
                            <Link to={`/post/${item.slug}`} state={item} onDragStart={handleImageLinkDrage} className='nav-link w-100 h-100 fw-600'>
                                {item.title}
                            </Link>
                        </p>
                    </div>

                    <div className="col-12 text-end d-flex-end">
                        <div className='col-5 search-footer'>
                            {FormatArabicDate(item.created_at)} <Icons.MdOutlineWatchLater className='clr-danger' />
                        </div>
                        <div className='col-3 search-footer'>
                            {item.postedBy} <Icons.FaRegUser className='clr-danger' />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default SearchPostsCard
