import { FC } from 'react'
import './CardHeader.css'

interface CardHeaderProps {
    label: string
}

const CardHeader: FC<CardHeaderProps> = ({ label }) => {
    return (
        <div className="card-header d-flex-end pe-4 pt-3 h-4rem mb-2">
            <p className='fw-600 fs-19px'>{label}</p>
        </div>
    )
}

export default CardHeader