import { useWindowWidthContext } from '../contexts/WindowWidthProvider';
import { useUserContext } from '../contexts/UsersContext';

import useClickInside from '../hooks/useClickInside';
import useClickOutside from '../hooks/useClickOutside';

import { useFormik } from "formik";
import FormInput from '../components/FormInput/FormInput';
import SubmitButton from '../components/SubmitButton/SubmitButton';
import axios from 'axios';

import AnimatedLoading from '../components/Loading/AnimatedLoading';
import DottedLoading from '../components/Loading/DottedLoading';

// Default Components
export { default as Navbar } from '../components/Navbar/Navbar';
export { default as Footer } from '../components/Footer/Footer';
export { default as HeaderContainer } from '../components/HeaderContainer/HeaderContainer';
export { default as FormInput } from '../components/FormInput/FormInput';
export { default as SubmitButton } from '../components/SubmitButton/SubmitButton';
export { default as PostCard } from '../components/PostCard/PostCard';

// Images
export { default as facebook } from '../../assets/images/login/facebook-icon.png'
export { default as noResults } from '../../assets/images/search/noResults.png'
export { default as logo } from '../../assets/images/logo/logonasnews.png';
export { default as iconNasnews } from '../../assets/images/logo/nasnews-logo.png';


export const contexts = {
    useWindowWidthContext,
    useUserContext
};

export const hooks = {
    useClickInside,
    useClickOutside
};

export const formHelper = {
    useFormik,
    FormInput,
    SubmitButton,
    axios,
};

export const loadingHelper = {
    AnimatedLoading,
    DottedLoading
};
