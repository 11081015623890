import { FC } from 'react';
import error from '../../../assets/images/error/404.png';
import { useNavigate } from 'react-router-dom';
import './Error404.css';

const Error404: FC = () => {

    const navigate = useNavigate();

    const home = () => {
        navigate('/')
    }
    return (
        <div className="error-container bg-clr-light">
            <div className="error-logo d-flex-center h-100">
                <img src={error} alt="" className='error-img' />
            </div>
            <div className="text-center text-light">
                <button className="btn-error mt-5 ff-3 fw-600" onClick={home} >
                    العودة إلى الصفحة الرئيسية
                </button>
            </div>
        </div>
    );
}

export default Error404;
