import { FC } from 'react'
import { PostCardProps } from '../../helpers/Props'
import { FormatArabicDate, blogImgUrl, handleImageLinkDrage } from '../../helpers/Helpers'
import Icons from '../../helpers/IconsHelper'
import './SpecialPostsCard.css'
import { Link } from 'react-router-dom'

const SpecialPostsCard: FC<PostCardProps> = ({ item }) => {

    return (
        <div className='container'>

            <div className="row">
                <div className="col-12">
                    <div className="special-post-image o-hidden radius-10px c-pointer">
                        <Link to={`/post/${item.slug}`} state={item} onDragStart={handleImageLinkDrage} className='nav-link w-100 h-100'>
                            <img src={`${blogImgUrl}/${item.image}`} alt={item.title} className='special-post-img radius-10px' />
                        </Link>
                    </div>
                </div>

                <div className="col-12 mt-2">
                    <p className='clr-dark title-hover text-end'>
                        <Link to={`/post/${item.slug}`} state={item} onDragStart={handleImageLinkDrage} className='nav-link w-100 h-100 fw-600'>
                            {item.title}
                        </Link>
                    </p>
                </div>

                <div className="col-12 text-end d-flex-end">
                    <div className='col-6 special-footer'>
                        {FormatArabicDate(item.created_at)} <Icons.MdOutlineWatchLater className='clr-danger' />
                    </div>
                    <div className='col-6 special-footer'>
                        {item.postedBy} <Icons.FaRegUser className='clr-danger' />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default SpecialPostsCard
