import { FC } from 'react'
import { PostCardProps } from '../../helpers/Props'
import { FormatArabicDate, blogImgUrl, handleImageLinkDrage } from '../../helpers/Helpers'
import { Link } from 'react-router-dom'
import Icons from '../../helpers/IconsHelper'

import './PopularPostCard.css'
import { contexts } from '../../helpers/Index'

const PopularPostCard: FC<PostCardProps> = ({ item }) => {

    const { windowWidth } = contexts.useWindowWidthContext()

    return (
        <div className='container px-2 pb- min-h-5rem'>
            <div className="row">
                <div className="col-8 text-end">
                    <div className="row min-h-3rem pt-1">
                        <p className={`clr-dark title-hover mb-1 ${windowWidth < 578 ? 'fs-15px' : ''}`}>
                            <Link to={`/post/${item.slug}`} state={item} onDragStart={handleImageLinkDrage} className='nav-link w-100 h-100 fw-600'>
                                {item.title}
                            </Link>
                        </p>

                    </div>
                    <div className="row">
                        <p>
                            {FormatArabicDate(item.created_at)} <Icons.MdOutlineWatchLater className='clr-danger' />
                        </p>
                    </div>
                </div>

                <div className="col-4 mt-2">
                    <div className="popular-post-image o-hidden radius-10px c-pointer">
                        <Link to={`/post/${item.slug}`} state={item} onDragStart={handleImageLinkDrage} className='nav-link w-100 h-100'>
                            <img src={`${blogImgUrl}/${item.image}`} alt={item.title} className='popular-post-img radius-10px' />
                        </Link>
                    </div>
                </div>
            </div>

            <hr className='hr-tag my-0' />
        </div>
    )
}

export default PopularPostCard
