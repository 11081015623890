import * as yup from 'yup'

export const loginSchema = yup.object().shape({
    email: yup.string().email("Format d'e-mail non valide. Veuillez entrer une adresse e-mail valide.")
        .required("Veuillez saisir votre email"),
    password: yup.string().required("Veuillez saisir votre mot de passe"),
})

export const registerSchema = yup.object().shape({
    nom: yup.string().required("Veuillez saisir votre nom"),
    prenom: yup.string().required("Veuillez saisir votre prenom"),
    telephone: yup.string().required("Veuillez saisir votre numéro de telephone"),
    whatsapp: yup.string().required("Veuillez saisir votre numéro de whatsapp"),
    email: yup.string().email("Format d'e-mail non valide. Veuillez entrer une adresse e-mail valide.")
        .required("Veuillez saisir votre email"),
    password: yup.string().required("Veuillez saisir votre mot de passe").min(6, 'Password must be at least 6 characters'),
    // confirmPassword: yup.string().required('Veuillez confirmer votre mot de passe')
    //     .oneOf([yup.ref('password'), ''], 'Veuillez confirmer votre mot de passe'),
})

export const userAccountSchema = yup.object({
    nom: yup.string().required("Veuillez saisir votre nom"),
    prenom: yup.string().required("Veuillez saisir votre prenom"),
    telephone: yup.string().required("Veuillez saisir votre numéro de telephone"),
});

export const userPasswordSchema = yup.object({
    password: yup.string().required("Veuillez saisir votre mot de passe actuel"),
    newpassword: yup.string().required("Veuillez saisir votre mot de passe")
        .min(6, 'Password must be at least 6 characters'),
    confirmPassword: yup.string().required('Veuillez confirmer votre mot de passe')
        .oneOf([yup.ref('newpassword'), ''], 'Veuillez confirmer votre mot de passe'),
});

export const OrderAdresseSchema = yup.object({
    nom: yup.string().required("Veuillez saisir votre nom"),
    prenom: yup.string().required("Veuillez saisir votre prenom"),
    rueInfo: yup.string().required("Veuillez saisir le numéro et le nom de rue"),
    ville: yup.string().required("Veuillez saisir votre ville"),
    region: yup.string().required("Veuillez saisir votre région"),
    codepostal: yup.string().required("Veuillez saisir le code postal"),
    telephone: yup.string().matches(/^(05|06|07)[0-9]{8}$/, 'Numéro de téléphone invalide').required('Veuillez saisir votre telephone'),
    email: yup.string().email('Email invalide').required('Veuillez saisir votre adresse e-mail'),
})

export const codePpromoSchema = yup.object({
    codepromo: yup.string().required('Ce champ est obligatoire'),
});

export const forgotPasswordSchema = yup.object({
    email: yup.string().email("Format d'e-mail non valide. Veuillez entrer une adresse e-mail valide.")
        .required("Veuillez saisir votre email"),
});

export const resetPasswordSchema = yup.object({
    password: yup.string().required("Veuillez saisir votre mot de passe"),
    confirmPassword: yup.string().required('Veuillez confirmer votre mot de passe')
        .oneOf([yup.ref('password'), ''], 'Veuillez confirmer votre mot de passe'),
});

export const emailSchema = yup.object().shape({
    email: yup.string().email("البريد الإلكتروني غير صحيح")
        .required("ادخل بريدك الإلكتروني")
})
