import React, { ReactNode } from 'react';
import './SubmitButtonStyle.css';

interface ButtonProps {
  className?: string;
  btnLabel?: string;
  content?: ReactNode;
  type?: 'submit' | 'button' | 'reset';
  onClick?: () => void;
  onSubmit?: () => void;
  disabled?: boolean | undefined
}

const SubmitButton: React.FC<ButtonProps> = ({
  className = '',
  btnLabel = '',
  content= '',
  type = 'submit',
  onClick,
  onSubmit,
  disabled =false
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    if (onSubmit) {
      onSubmit();
    }
  };

  return (
    <button type={type} className={`btn submit-btn ${className}`} disabled={disabled} onClick={handleClick}>
      {content}{btnLabel}
    </button>
  );
};

export default SubmitButton;
