import axios from "axios";
import { apiUrl } from "./Helpers";
import { ApiRequestProps } from "./Props";

export function useApiRequest() {

    const ApiRequest = async ({
        route,
        method,
        requiresAuth = false,
        body,
        formData,
        headers,
    }: ApiRequestProps) => {
        try {
            let response: any;
            const url = `${apiUrl}/${route}`;

            if (method === "POST") {
                response = await axios.post(url, body, {
                    headers
                });
            } else if (method === "GET") {
                response = await axios.get(url, {
                    headers
                });

            } else if (method === "DELETE") {
                response = await axios.delete(url, {
                    headers
                });

            } else if (method === "PUT") {
                response = await axios.put(url, body, {
                    headers
                });

            } else if (method === "PATCH") {
                response = await axios.patch(url, body, {
                    headers
                });
            }

            return response;
        } catch (error) {
            throw error;
        }
    };

    return ApiRequest;
}