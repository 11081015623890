import { FC } from 'react';
import Icons from '../../helpers/IconsHelper';
import { SubmitButton, formHelper } from '../../helpers/Index';
import { emailSchema } from '../../helpers/YupHelper';
import { useApiRequest } from '../../helpers/ApiRequest';
import { ErrorNotify, FrCustomeErrorNorify, notify } from '../../helpers/Toastify';
import './NewsEmail.css'
import CardHeader from '../CardHeader/CardHeader';

const NewsEmail: FC = () => {
  const apiRequest = useApiRequest();

  const formik = formHelper.useFormik({
    initialValues: {
      email: ""
    },
    validationSchema: emailSchema,
    onSubmit: async (values, { resetForm }) => {
      try {

        const checkResponse = await apiRequest({
          route: `check-news-email/${values.email}`,
          method: 'GET',
          body: values
        })

        if (checkResponse.status === 200) {
          // console.log(checkResponse.data.found);

          if (checkResponse.data.found === false) {
            const response = await apiRequest({
              route: `news_emails`,
              method: 'POST',
              body: values
            })

            if (response.status === 201) {
              notify('تم الإشتراك بنجاح')
              resetForm()
            } else {
              FrCustomeErrorNorify()
              resetForm()
            }
          } else {
            ErrorNotify('لقد قمت بالإشتراك من قبل')
            resetForm()
          }
        } else {

        }

      } catch {
      }
    },
  });

  return (
    <>
      <div className="container following-card bg-clr-light radius-10px mb-3">

        <div className="row d-flex-start">
          <CardHeader label='اشترك معنا' />
        </div>

        <div className="row d-flex-start mt-2 pe-2 dir-rtl">
          <form onSubmit={formik.handleSubmit}>
            <div className="input-group dir-rtl">
              <formHelper.FormInput
                label=""
                spanValue="البريد الإلكتروني"
                name="email"
                type="email"
                value={formik.values.email}
                className={formik.touched.email && formik.errors.email ? "is-invalid dir-rtl" : "dir-rtl"}
                touched={formik.touched}
                errors={formik.errors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
              />

              <div className="input-group-append d-none">
                <span className="input-group-textt">
                  <Icons.FaTelegramPlane className='clr-dark sendemail' />
                </span>
              </div>
            </div>

            <SubmitButton btnLabel="إرسال" className="mt-1 mb-1 ff-1 fw-600 w-100" />
          </form>
        </div>

        {/* <div className="row d-flex-start mt-2 pe-20px ps-13px dir-rtl">
          <SubmitButton btnLabel="إرسال" className="mt-1 mb-1 ff-1 fw-600" />
        </div> */}
      </div>
    </>
  );
};

export default NewsEmail;