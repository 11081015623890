import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from '../pages/Home/Home';
import Login from '../pages/Auth/Login';
import Register from '../pages/Auth/Register';
import TermsOfUse from '../pages/Terms/TermsOfUse';
import TermsOfSale from '../pages/Terms/TermsOfSale';
import ForgotPassword from '../pages/Auth/ForgotPassword';
import ResetPassword from '../pages/Auth/ResetPassword';
import Error404 from '../pages/Errors/Error404';
import PostDetails from '../pages/PostDetails/PostDetails';
const Routing: React.FC = () => {

  return (
    <Router basename="/">
      <Routes>
        {/* -------- Global */}
        <Route path="*" element={<Error404 />} />
        <Route path="/" element={<Home />} />

        {/* -------- Blog */}
        <Route path="/post/:slug" element={<PostDetails />} />

      </Routes>
    </Router>
  );
};

export { Routing };