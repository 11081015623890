import './App.css';
import './assets/sass/App.scss';
import "react-toastify/dist/ReactToastify.css";
import { Routing } from './app/routing/Routing';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import { HelmetProvider } from 'react-helmet-async';
import { BackToTop } from './app/components/BackToTop/BackToTop';
import { WindowWidthProvider } from './app/contexts/WindowWidthProvider';
import { UserProvider } from './app/contexts/UsersContext';

function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <UserProvider>
        <WindowWidthProvider>
          <HelmetProvider>
            <ToastContainer limit={5} />
            <Routing />
            <BackToTop />
          </HelmetProvider>
        </WindowWidthProvider>
      </UserProvider>
    </QueryClientProvider >
  );
}

export default App;