import PopularPosts from '../../components/PopularsPosts/PopularPosts';
import SpecialPosts from '../../components/SpecialPosts/SpecialPosts';
import Following from '../../components/Following/Following';
import './LeftSide.css'
import NewsEmail from '../NewsEmail/NewsEmail';


const LeftSide: React.FC = () => {

    return (
        <div className="col-xl-4 col-12 left-side">
            <Following />
            <NewsEmail />
            <PopularPosts />
            <SpecialPosts />
        </div>
    )
}

export default LeftSide;