import React, { useEffect, useState } from 'react'
import ReviewItem from './ReviewItem'
import FormInput from '../FormInput/FormInput'
import SubmitButton from '../SubmitButton/SubmitButton'
import { useFormik } from 'formik'
import * as yup from "yup"
import { AuthUserProps, ReviewFormProps, ReviewsProps } from '../../helpers/Props'
import { useApiRequest } from '../../helpers/ApiRequest'
import { FormatDate, apiUrl } from '../../helpers/Helpers'
import { notify, ArCustomeErrorNorify } from '../../helpers/Toastify'
import DottedLoading from '../Loading/DottedLoading'
import { Pagination } from '@mui/material'
import { formHelper } from '../../helpers/Index'
import './ReviewsStyle.css'

const ReviewForm: React.FC<ReviewFormProps> = ({ blogId, state, post, id }) => {

  const [authUser, setAuthUser] = useState<AuthUserProps | null>(null)
  const storedUser = localStorage.getItem('auth_user')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (storedUser) {
      setAuthUser(JSON.parse(storedUser))
    }
  }, [state ? state : post, id])


  const [blogReviews, setBlogReviews] = useState<ReviewsProps[]>([])
  const [ReviewsCountCheck, setReviewsCountCheck] = useState(false)
  const [ReviewsCount, setReviewsCount] = useState(0)
  const [ReviewsTotal, setReviewsTotal] = useState(0)
  const apiRequest = useApiRequest()
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [totalItems, setTotalItems] = useState(0)


  const status = 'pending'
  const blog = `api/blogs/${blogId}`
  const user = `api/users/${authUser?.id}`

  const validationSchema = yup.object({
    message: yup.string().required("المرجو كتابة تعليق"),
  })

  const formik = useFormik({
    initialValues: {
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      let data
      data = { ...values, blog, user, createdAt: FormatDate(new Date()), updatedAt: FormatDate(new Date()), status }

      try {

        if (authUser?.id) {
          setIsLoading(true)

          const response = await apiRequest({
            route: `user/check-user-info/${authUser?.id}`,
            method: 'GET',
          })

          if (response.status === 200) {
            if (response.data.result === 'true') {
              const response = await apiRequest({
                route: `reviews`,
                method: 'POST',
                body: data
              })

              if (response.status === 201) {
                notify('تم إرسال تعليقك بنجاح')
                setIsLoading(false)
                resetForm()
              }
            }

          }

        }

      } catch (error) {
        ArCustomeErrorNorify()
        setIsLoading(false)
      }

    },
  })

  const {
    touched,
    errors,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
  } = formik


  const fetchReviews = async () => {
    try {
      const response = await formHelper.axios.get(`${apiUrl}/reviews.jsonld?blog.id=${blogId}`, {
        params: {
          page: currentPage,
          itemsPerPage: 10
        },
        headers: {
          Accept: 'application/ld+json'
        }
      })

      if (response.status === 200) {
        if (response.data['hydra:member'].length > 0) {
          setBlogReviews(response.data['hydra:member'])
          setReviewsTotal(response.data['hydra:totalItems'])
          setReviewsCount(response.data['hydra:totalItems'].length)
          setReviewsCountCheck(true)
        } else {
          setBlogReviews(response.data['hydra:member'])

        }
      } else {
        ArCustomeErrorNorify()
      }

    } catch {
    }
  }

  useEffect(() => {
    fetchReviews()
  }, [currentPage, state ? state : post, blogId, id])

  const handlePageChange = (event: any, value: any) => {
    setCurrentPage(value)
  }


  return (
    <div className="review-form mt-3 pe-3">

      <div className={``}>
        <div className="col-12"></div>
        <form onSubmit={handleSubmit}>
          <div className="col-12 d-none">
            <h3 className="text-end text-blue fw-bold mb-5 mt-">إضافة تعليق</h3>
          </div>

          <div className="col-xl-5 col-lg-7 col-md-10 col-12 mb-2 dir-rtl">
            <FormInput
              label="تعليق *"
              spanValue="تعليق"
              name="message"
              textarea={true}
              rows={2}
              value={values.message}
              className={touched.message && errors.message ? 'is-invalid dir-rtl' : 'dir-rtl'}
              touched={touched}
              errors={errors}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>

          <div className="col-12 col-md-12 col-lg-12 col-xl-12 review-box-btn mt-2">
            {
              isLoading ?
                <DottedLoading />
                :
                <SubmitButton btnLabel="إرسال" className="mt-1 mb-1 ff-1 fw-600" />
            }
          </div>
        </form>
      </div>

      <hr className={`hr-tag`} />

      <div className="row pe-">
        <div className="col-12 px-">
          {ReviewsCountCheck === true ?
            <>
              {blogReviews.map(review => (
                <ReviewItem key={review.id}
                  name={`${review.user.fullName}`}
                  comment={review.message}
                />
              ))
              }
            </>
            :
            ''
          }
        </div>

      </div>

      <div className="row m-0">
        {blogReviews.length > 5 ?
          <div className="container dir-ltr bg-clr-light radius-10px h-40px d-flex-center position-relative">
            <Pagination
              count={totalPages}
              page={currentPage}
              color='primary'
              onChange={handlePageChange}
              className='d-flex-center'
            />
          </div>
          :
          null
        }
      </div>

    </div>
  )
}

export default ReviewForm