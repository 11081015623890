import { FC } from 'react';
import { Link } from 'react-router-dom';
import CardHeader from '../CardHeader/CardHeader';
import Icons from '../../helpers/IconsHelper';
import './Following.css'

const Following: FC = () => {


  return (
    <>
      <div className="container following-card bg-clr-light radius-10px mb-3">

        <div className="row d-flex-start">
          <CardHeader label='انضم إلينا' />
        </div>

        <div className="row d-flex-center mb-3 mt-2 px-5">
          <div className="col-lg-4 col-6 mb-3 d-flex-center">
            <div className="following-icon d-flex-center radius-17px bg-color-ins">
              <Link to='/' className='bm-link pb-1'>
                <Icons.RiInstagramFill className='clr-light fs-19px' />
              </Link>
            </div>
          </div>
          <div className="col-lg-4 col-6 mb-3 d-flex-center">
            <div className="following-icon d-flex-center radius-17px bg-color-tel">
              <Link to='/' className='bm-link pb-1'>
                <Icons.FaTelegramPlane className='clr-light fs-19px' />
              </Link>
            </div>
          </div>
          <div className="col-lg-4 col-6 mb-3 d-flex-center">
            <div className="following-icon d-flex-center radius-17px bg-color-fb">
              <Link to='/' className='bm-link pb-1'>
                <Icons.FaFacebookF className='clr-light fs-19px' />
              </Link>
            </div>
          </div>

          <div className="col-lg-4 col-6 mb-3 d-flex-center">
            <div className="following-icon d-flex-center radius-17px bg-color-in">
              <Link to='/' className='bm-link pb-1'>
                <Icons.FaLinkedinIn className='clr-light fs-19px' />
              </Link>
            </div>
          </div>
          <div className="col-lg-4 col-6 mb-3 d-flex-center">
            <div className="following-icon d-flex-center radius-17px bg-color-tw">
              <Link to='/' className='bm-link pb-1'>
                <Icons.FaTwitter className='clr-light fs-19px' />
              </Link>
            </div>
          </div>
          <div className="col-lg-4 col-6 mb-3 d-flex-center">
            <div className="following-icon d-flex-center radius-17px bg-color-yt">
              <Link to='/' className='bm-link pb-1'>
                <Icons.FaYoutube className='clr-light fs-19px' />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Following;