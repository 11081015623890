import { FC, useEffect, useState } from 'react'
import { PostProps } from '../../helpers/Props'
import { PostCard, formHelper } from '../../helpers/Index'
import { apiUrl, handleScrollToTop } from '../../helpers/Helpers'
import { ArCustomeErrorNorify } from '../../helpers/Toastify'
import { Pagination } from '@mui/material'
import './PostsList.css'


const PostsList: FC = () => {
    const [posts, setPosts] = useState<PostProps[]>([])
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [totalItems, setTotalItems] = useState(0)

    const fetchData = async () => {
        try {
            const response = await formHelper.axios.get(`${apiUrl}/blogs.jsonld`, {
                params: {
                    page: currentPage,
                    itemsPerPage: 7
                },
                headers: {
                    Accept: 'application/ld+json'
                }
            })
            if (response.status === 200) {
                setPosts(response.data['hydra:member'])
                setTotalItems(response.data['hydra:totalItems'])
                setTotalPages(Math.ceil(response.data['hydra:totalItems'] / 10))
                if (currentPage > 1) {
                    handleScrollToTop()
                }
            } else {
                ArCustomeErrorNorify()
            }
        } catch {
            ArCustomeErrorNorify()
        }
    }

    useEffect(() => {
        fetchData()
    }, [currentPage, totalItems])

    const handlePageChange = (event: any, value: any) => {
        setCurrentPage(value)
    }


    return (
        <>
            <div className='container post-list bg-clrlight'>
                <div className="row justify-content-center">
                    {posts.length > 0 ? posts.map((item) =>
                        <PostCard item={item} key={item.id} />
                    ) : null}
                </div>
            </div>

            {posts.length > 0 ?
                <div className="container bg-clr-light mb-3 radius-10px h-40px d-flex-center position-relative">
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        color='primary'
                        onChange={handlePageChange}
                        className='d-flex-center '
                    />
                </div>
                :
                null
            }
        </>
    )
}

export default PostsList