import { FC } from 'react'
import { PostCardProps } from '../../helpers/Props'
import { FormatArabicDate, TruncateText, blogImgUrl, decodeHtmlTags, extractTextFromHTML, handleImageLinkDrage } from '../../helpers/Helpers'
import Icons from '../../helpers/IconsHelper'
import './PostCard.css'
import { Link } from 'react-router-dom'

const PostCard: FC<PostCardProps> = ({ item }) => {

  return (
    <div className='container mb-3 p-4 radius-10px bg-clr-light'>
      <div className="row d-flex-center">
        <div className="col-12">
          <div className="post-card-image shadow-1 o-hidden radius-10px c-pointer">
            <Link to={`/post/${item.slug}`} state={item} onDragStart={handleImageLinkDrage} className='nav-link w-100 h-100'>
              <img src={`${blogImgUrl}/${item.image}`} alt={item.title} className='post-card-img radius-10px' />
            </Link>
          </div>
        </div>

        <div className="col-12 mt-4">
          <h1 className='clr-dark title-hover text-center'>
            <Link to={`/post/${item.slug}`} state={item} onDragStart={handleImageLinkDrage} className='nav-link w-100 h-100'>
              {item.title}
            </Link>
          </h1>
        </div>

        <div className="col-12 mt-2 text-center d-flex-center">
          <div className='col-lg-3 col-6'>
            {FormatArabicDate(item.created_at)} <Icons.MdOutlineWatchLater className='clr-danger' />
          </div>
          <div className='col-lg-4 col-6'>
            {item.postedBy} <Icons.FaRegUser className='clr-danger' />
          </div>
        </div>

        <div className="col-10 mt-4 text-center d-flex-center fs-19px">
          <TruncateText text={extractTextFromHTML(decodeHtmlTags(item.description))} maxLength={150} />
        </div>

      </div>

    </div>
  )
}

export default PostCard
