import { AiFillHome } from 'react-icons/ai'
import { BiPlus } from 'react-icons/bi'
import { BsGridFill, BsFacebook, BsCheckCircleFill, BsPlusCircle, BsCircle, BsStars, BsFillTrashFill, BsSearch } from 'react-icons/bs'
import { CiGrid2H, CiCircleMore } from 'react-icons/ci'
import { FaFilter, FaRegUser, FaRegComments, FaFacebookF, FaLinkedinIn, FaYoutube, FaTwitter, FaTelegramPlane } from 'react-icons/fa'
import { FiShoppingCart, FiSearch } from 'react-icons/fi'
import { GrFormClose } from 'react-icons/gr';
import { HiOutlineMinusSm, HiOutlineUserCircle, HiMinusSm } from 'react-icons/hi'
import { ImPhone } from 'react-icons/im'
import {
    MdExpandLess,
    MdShoppingCartCheckout,
    MdOutlineHelpOutline,
    MdShoppingCart,
    MdOutlineFavorite,
    MdExpandMore,
    MdModeEditOutline,
    MdDelete,
    MdWhatsapp,
    MdContactMail,
    MdOutlineWatchLater
} from "react-icons/md"

import { RiMailOpenFill, RiInstagramFill } from 'react-icons/ri'

const Icons = {
    AiFillHome,
    BiPlus,
    BsGridFill,
    BsFacebook,
    BsCheckCircleFill,
    BsCircle,
    BsPlusCircle,
    BsStars,
    BsSearch,
    BsFillTrashFill,
    CiGrid2H,
    CiCircleMore,
    FaFilter,
    FaRegUser,
    FaRegComments,
    FaFacebookF,
    FaLinkedinIn,
    FaYoutube,
    FaTwitter,
    FaTelegramPlane,
    FiShoppingCart,
    FiSearch,
    GrFormClose,
    HiMinusSm,
    HiOutlineMinusSm,
    HiOutlineUserCircle,
    ImPhone,
    MdDelete,
    MdExpandLess,
    MdExpandMore,
    MdModeEditOutline,
    MdOutlineFavorite,
    MdOutlineHelpOutline,
    MdShoppingCart,
    MdShoppingCartCheckout,
    MdWhatsapp,
    MdContactMail,
    MdOutlineWatchLater,
    RiMailOpenFill,
    RiInstagramFill
};

export default Icons;