import { FC, useEffect, useState } from 'react';
import { useApiRequest } from '../../helpers/ApiRequest';
import { HeaderContainer, formHelper } from '../../helpers/Index';
import { useInfiniteQuery } from 'react-query';
import { apiUrl } from '../../helpers/Helpers';
import { ArCustomeErrorNorify } from '../../helpers/Toastify';
import { PostProps } from '../../helpers/Props';
import './SpecialPosts.css'
import SpecialPostsCard from '../SpecialPostsCard/SpecialPostsCard';
import CardHeader from '../CardHeader/CardHeader';

const SpecialPosts: FC = () => {
    const [products, setProducts] = useState<any[]>([]);
    const apiRequest = useApiRequest()
    const [isLoadingMoreData, setIsLoadingMoreData] = useState(false)
    const [isLoadingData, setIsLoadingData] = useState(true)
    const [posts, setPosts] = useState<PostProps[]>([])

    const fetchData = async () => {
        try {
            const response = await formHelper.axios.get(`${apiUrl}/blogs.jsonld`, {
                params: {
                    page: 1,
                    itemsPerPage: 1
                },
                headers: {
                    Accept: 'application/ld+json'
                }
            })
            if (response.status === 200) {
                setPosts(response.data['hydra:member'])
            } else {
                ArCustomeErrorNorify()
            }
        } catch {
            ArCustomeErrorNorify()
        }
    }

    useEffect(() => {
        fetchData()
    }, [])


    return (
        <>
            {posts.length > 0 ?
                <div className="container special-posts bg-clr-light radius-10px mb-3">
                    <div className="row d-flex-start" >
                        <CardHeader label='مشاركة مميزة' />
                    </div>

                    <div className="row d-flex-center p-2">
                        {posts.map((item) =>
                            <SpecialPostsCard item={item} key={item.id} />
                        )}
                    </div>
                </div>
                :
                null
            }
        </>
    );
};

export default SpecialPosts;