import { AnimatedLoadingProps } from '../../helpers/Props'
import './loadingDataStyle.css'

const AnimatedLoading: React.FC<AnimatedLoadingProps> = ({ className, label, isBoutique }) => {

    return (
        <div className={`loading-data animated-loading ${className} ${isBoutique ? 'col-xl-9 col-lg-8 col-md-8 col-sm-12 col-12' : ''}`}>
            <span className='loading-data-text text-center clr-info ff-1 fw-600 fs-5 mt-2 d-none'>Chargement {label}...</span>
            <div className="animation-bar-1">
                <span></span>
            </div>
        </div>
    )
}

export default AnimatedLoading