import { FC, useState, useEffect, useRef, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useApiRequest } from '../../helpers/ApiRequest'
import { FormatArabicDate, NavLink, apiUrl, blogImgUrl } from '../../helpers/Helpers'
import { contexts, formHelper, logo } from '../../helpers/Index'
import { ArCustomeErrorNorify } from '../../helpers/Toastify'
import { PostProps } from '../../helpers/Props'
import Icons from '../../helpers/IconsHelper'
import OffCanvas from '../OffCanvas/OffCanvas'
import SearchBox from '../SearchBox/SearchBox'
import './NavBarStyle.css'

let timeout: NodeJS.Timeout
let latestPosttTimeout: NodeJS.Timeout

const Navbar: FC = () => {

  useEffect(() => {
    return () => {
      clearTimeout(timeout)
      clearTimeout(latestPosttTimeout)
    }
  }, [])

  // Global
  const apiRequest = useApiRequest()
  const { windowWidth } = contexts.useWindowWidthContext()

  // States
  const [searchBoxVisible, setSearchBoxVisible] = useState(false)
  const [isLatestPostDropdownOpen, setIsLatestPostDropdownOpen] = useState(false)
  const [textValue, setTextValue] = useState('')
  const [blogs, setBlogs] = useState<PostProps[]>([])
  const [posts, setPosts] = useState<PostProps[]>([])

  // Use Click Outside
  const elementRef = useRef<HTMLDivElement>(null)
  const blockRef = useRef<HTMLDivElement>(null)

  // OffCanvas close
  const closeLatestPostBtnRef = useRef<HTMLButtonElement | null>(null)
  const closeNavbarBtnRef = useRef<HTMLButtonElement | null>(null)

  const closeOffCanvasLatestPost = () => {
    if (closeLatestPostBtnRef.current) {
      closeLatestPostBtnRef.current.click()
    }
  }


  // Search box close
  const closeSearchBoxBtnRef = useRef<HTMLDivElement>(null)
  const closeSearchBoxRef = useRef<HTMLDivElement>(null)

  const closeSearchBox = () => {
    if (closeSearchBoxBtnRef.current) {
      closeSearchBoxBtnRef.current.click()
    }
  }


  const handleLatestPostDropdownMouseEnter = () => {
    clearTimeout(latestPosttTimeout)
    setIsLatestPostDropdownOpen(true)
  }

  const handleLatestPostDropdownMouseLeave = () => {
    latestPosttTimeout = setTimeout(() => {
      setIsLatestPostDropdownOpen(false)
    }, 200)
  }

  useEffect(() => {
    if (!searchBoxVisible) {
      setBlogs([])
      setTextValue('')
    }
  }, [searchBoxVisible])

  const handleSearchIconClick = () => {
    setSearchBoxVisible(!searchBoxVisible)
  }

  const searchBlogs = async (text: string) => {
    try {
      const response = await apiRequest({
        route: `blogs.json?title=${text}`,
        method: 'GET',
      })

      if (response.status === 200) {
        setBlogs(response.data)
      } else {
      }

    } catch (error) {
    }
  }


  const fetchData = async () => {
    try {
      const response = await formHelper.axios.get(`${apiUrl}/blogs.jsonld`, {
        params: {
          page: 1,
          itemsPerPage: 4,
          'order[id]': 'desc'
        },
        headers: {
          Accept: 'application/ld+json'
        }
      })
      if (response.status === 200) {
        setPosts(response.data['hydra:member'])
      } else {
        ArCustomeErrorNorify()
      }
    } catch {
      ArCustomeErrorNorify()
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const latestPostContent =
    <>
      <div className="row o-hidden" >
        {posts.map((item) => (
          <Fragment key={item.slug}>
            <div className={`col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6  ${windowWidth < 578 ? 'scale-ff d-flex-center' : 'd-flex-end'} flex-wrap`}  >
              <div className="row">
                <div className="m-2 latestpost-image-container">
                  <Link to={`/post/${item.slug}`} state={item} onClick={closeOffCanvasLatestPost} >
                    <img
                      src={`${blogImgUrl}/${item.image}`}
                      alt={item.title}
                      className='nav-item-latestpost-image' />
                  </Link>
                </div>
              </div>

              <div className="row">
                <div className={`nav-item-latestpost-title text-end ${windowWidth < 578 ? 'd-flex-end' : 'd-flex-end flex-wrap ps-0'}`}>
                  <Link className='bm-link' to={`/post/${item.slug}`} state={item} >
                    <p className='ff-1 fw-600 m-0 c-pointer clr-dark'>{item.title}</p>
                  </Link>
                </div>
              </div>

              <div className="row">
                <div className='col-12 special-footer'>
                  {FormatArabicDate(item.created_at)} <Icons.MdOutlineWatchLater className='clr-danger' />
                </div>
              </div>

            </div>

          </Fragment>
        ))}
      </div>
    </>


  const navbarContent =
    <div className="row justify-content-center ai-center" >
      <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
        <div className="container-fluid d-flex-start">
          <div className="w-100" id="">
            <Link to="/">
              <img src={logo} alt="nasnews" className='offcanvas-logo mb-5' />
            </Link>
            <ul className={`navbar-nav me-auto mb-2 mb-lg-0 ${windowWidth < 578 ? 'pe-0' : ''}`}>
              <NavLink to="/" label="الرئيسية" />

              {/* Start LatestPost */}
              <li className="nav-item pe-4 dropdown"
                onMouseEnter={handleLatestPostDropdownMouseEnter}
                onMouseLeave={handleLatestPostDropdownMouseLeave}
              >
                <a
                  className="nav-link navbar-link dropdownstoggle" href="/"
                  data-bs-toggle="offcanvas" data-bs-target="#offcanvaslatestPost" aria-controls="offcanvaslatestPost">
                  آخر المواضيع
                </a>

                <OffCanvas
                  position='end'
                  content={latestPostContent}
                  label='آخر المواضيع'
                  id='offcanvaslatestPost'
                  closeBtnRef={closeLatestPostBtnRef}
                  className=''
                />

              </li>
              {/* End LatestPost */}

            </ul>
          </div>
        </div>
      </div>
    </div>


  return (
    <>
      <header className="header-nav w-100 d-flex-start pt-2">
        <div className={`container w100 mxc-${windowWidth > 1320 ? '5' : windowWidth > 1140 ? '5' : '5'}`}>
          <div className="row justify-content-center ai-center">
            <div className={`${windowWidth > 992 ? 'col-xxl-5 col-xl-5 col-lg-5' : 'col-xxl-3 col-xl-3'} ${windowWidth < 992 ? 'd-none' : ''} ${windowWidth < 1140 ? 'col-categorie' : ''} col-md-6 col-sm-8 col-5  text-${windowWidth > 578 ? 'end' : 'center'} d-flex justify-content-start align-items-center`}>
              <nav className="navbar navbar-expand-lg navbar-right">
                <div className="container-fluid d-flex-start">
                  <div className="w-100 collapse navbar-collapse" id="navbarNavDropdown">

                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                      <li>
                        <div className={`p${windowWidth > 1400 ? 's' : 'e'}-4 pt-0`} ref={closeSearchBoxRef} onClick={handleSearchIconClick}>
                          <Icons.BsSearch className="navbar-icon clr-light fs-3 ms-sm-2 navbar-icon-search" />
                        </div>
                      </li>
                    </ul>
                  </div>

                </div>
              </nav>
            </div>

            <div className={`${windowWidth > 992 ? 'col-xxl-4 col-xl-4' : 'col-xxl-7 col-xl-7'} col-lg-5 col-md-11 col-sm-11 col-10`}>
              <nav className="navbar navbar-expand-lg navbar-right">
                <div className="container-fluid d-flex-start">

                  <div className="responsive-icon">
                    <button className={`navbar-toggler ${windowWidth < 992 ? 'collapsed' : ''}`}
                      type="button"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasnavbar"
                      aria-controls="offcanvasnavbar"
                      aria-expanded="false"
                      aria-label="Toggle navigation">

                      <span className="navbar-toggler-icon"></span>
                    </button>
                  </div>

                  <div className={`w-100 collapse navbar-collapse ${windowWidth < 992 ? 'd-none' : ''}`} id="navbarNavDropdown">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                      {/* Start LatestPost */}
                      <li className="nav-item pe-4 dropdown"
                        onMouseEnter={handleLatestPostDropdownMouseEnter}
                        onMouseLeave={handleLatestPostDropdownMouseLeave}
                      >

                        {windowWidth > 992 ?
                          <a className="nav-link navbar-link  dropdown-toggle"
                            href="/" id="navbarDropdownMenuLink"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            aria-label="آخر المواضيع"
                          >
                            آخر المواضيع
                          </a>
                          :
                          null
                        }

                        {windowWidth > 992
                          ?
                          <ul className={`dropdown-menu dropdown-menu-latestpost ${windowWidth > 520 ? isLatestPostDropdownOpen ? 'show' : '' : ''}`} aria-labelledby="navbarDropdownMenuLink">
                            {latestPostContent}
                          </ul>
                          :
                          null
                        }

                      </li>
                      {/* End LatestPost */}

                      <NavLink to="/" label="الرئيسية" />

                    </ul>
                  </div>
                </div>
              </nav>
            </div>

            <div className={`${windowWidth > 992 ? 'col-xxl-2 col-xl-2' : 'col-xxl-2 col-xl-2'} col-lg-2 col-md-12 col-sm-12 col-12 d-flex-${windowWidth > 1250 ? 'center' : windowWidth > 992 ? 'center' : 'start'}`}>
              <div className={`navbar-brand m-logo ${windowWidth < 992 ? 'ms3' : 'ms-0me-4'}`}>
                <Link to="/">
                  <img src={logo} alt="nasnews" className='nasnews logo-nasnews' />
                </Link>
              </div>
              {windowWidth < 992 ?
                <div className="d-flex-end w-100 search-contact">
                  <ul className="navbar-nav me-auto mb-2 mb-lg-0 w-100 d-contents">
                    <li className='text-end'>
                      <div className="pe-4 pt-2" onClick={handleSearchIconClick}>
                        <Icons.BsSearch className="navbar-icon clr-light ms-sm-2 navbar-icon-search" />
                      </div>
                    </li>
                  </ul>
                </div>
                :
                null}
            </div>

          </div>
        </div>

        {windowWidth < 992
          ?
          <OffCanvas
            position='start'
            content={navbarContent}
            label=''
            id='offcanvasnavbar'
            closeBtnRef={closeNavbarBtnRef}
            className='bg-clr-b1'
          />
          :
          null
        }

        {/* Start Search box */}
        <SearchBox
          searchBoxVisible={searchBoxVisible}
          textValue={textValue}
          posts={blogs}
          position='nav'
          setTextValue={setTextValue}
          searchBlogs={searchBlogs}
          onClick={closeSearchBox}

        />
        {/* End Search box */}

      </header>
    </>
  )
}

export default Navbar