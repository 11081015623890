import React from 'react'
import Icons from '../../helpers/IconsHelper'
import { PostProps } from '../../helpers/Props'
import { noResults } from '../../helpers/Index'
import './SearchBox.css'
import SearchPostsCard from '../SearchPostsCard/SearchPostsCard'

interface SearchBoxProps {
    searchBoxVisible: boolean
    textValue: string
    posts: PostProps[]
    position: string
    setTextValue: React.Dispatch<React.SetStateAction<string>>
    searchBlogs: (text: string) => Promise<void>
    onClick: () => void

}

const SearchBox: React.FC<SearchBoxProps> = ({
    searchBoxVisible,
    textValue,
    posts,
    position,
    setTextValue,
    searchBlogs,
    onClick
}) => {
    return (
        <div className={`search-box ${position === 'nav' ? 'nav' : 'top'}-box ${searchBoxVisible ? 'show' : ''}`}>
            <div className="row">
                <div className="col-12">
                    <div className="input-group justify-content-end">
                        <input
                            type="text"
                            value={textValue}
                            className="form-controll search-box-input dir-rtl"
                            placeholder="بحث..."
                            onChange={(e) => {
                                setTextValue(e.target.value)
                                searchBlogs(e.target.value)
                            }}
                        />
                        <div className="input-group-append">
                            <span className="input-group-textt">
                                <Icons.FiSearch className="search-box-icon" />
                            </span>
                        </div>
                    </div>
                </div>
                <hr className="hr-tag mt-3" />

                <div className="col-12 d-flex justify-content-center flex-wrap">
                    {posts.length > 0 ? (
                        posts.map((item) => (
                            <SearchPostsCard key={item.id} item={item} onClick={onClick}  />
                        ))
                    ) : textValue === '' ? (
                        ''
                    ) : (
                        <>
                            <div className="col-12 d-flex justify-content-center align-items-center col-">
                                <img src={noResults} alt="no results" className="no-search-result" />
                            </div>
                            <div className="col-12 d-flex justify-content-center align-items-center col-">
                                <div className="clr-dark fw-600 ff-0 fs-4 mn-13">لا يوجد مقال بهذا العنوان</div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default SearchBox