import { useState, useEffect, useRef } from 'react'
import { contexts, formHelper } from '../../helpers/Index'
import { LoginSocialGoogle, IResolveParams, } from 'reactjs-social-login'
import { GoogleLoginButton } from 'react-social-login-buttons'
import { useApiRequest } from '../../helpers/ApiRequest'
import { ArCustomeErrorNorify } from '../../helpers/Toastify'
import { JSONHeader, REACT_APP_GG_APP_ID, apiUrl, refreshPage } from '../../helpers/Helpers'
import './LoginStyle.css'

const Login: React.FC = () => {

  // Global
  const apiRequest = useApiRequest()
  const auth_user_token = localStorage.getItem('auth_user_token')
  const [isLogin, setIsLogin] = useState(false)
  const { windowWidth } = contexts.useWindowWidthContext()
  const withGoogleRef = useRef<HTMLButtonElement | null>(null)


  const handleElse = () => {
    ArCustomeErrorNorify()
  }
  const startWithGoogle = () => {
    if (withGoogleRef.current) {
      withGoogleRef.current.click()
    }
  }

  const [provider, setGoogleProvider] = useState('')
  const [googleProfile, setGoogleProfile] = useState<any>()

  //=============================== Start withGoogle
  const withGoogle = async () => {

    if (googleProfile) {

      const GPassword = `${googleProfile.email}${googleProfile.sub}`
      const formData = new FormData()
      formData.append('email', googleProfile.email)
      formData.append('password', GPassword)
      formData.append('fullName', googleProfile.name)
      formData.append('loginWith', 'google')

      const response = await apiRequest({
        route: `user/email/${googleProfile.email}`,
        method: 'GET',
      })

      if (response.status === 200) {
        if (response.data === 'Not Found') {
          try {
            const response = await formHelper.axios.post(`${apiUrl}/register`, formData)

            if (response.status === 200) {
              formData.delete('loginWith')

              const loginResponse = await apiRequest({
                route: `login`,
                method: 'POST',
                body: formData,
                headers: JSONHeader,
              })

              const token = loginResponse.data.token
              localStorage.setItem('auth_user_token', token)
              setIsLogin(true)
            } else {
              handleElse()
            }

          } catch (error) {
            handleElse()
          }

          // Start Found
        } else {
          formData.delete('loginWith')

          const loginResponse = await apiRequest({
            route: `login`,
            method: 'POST',
            body: formData,
            headers: JSONHeader,
          })

          if (loginResponse.status === 200) {

            const token = loginResponse.data.token
            localStorage.setItem('auth_user_token', token)
            setIsLogin(true)

          } else {
            handleElse()
          }
        }
      } else {
        handleElse()
      }

    } else {
      handleElse()
    }
  }

  useEffect(() => {
    if (googleProfile) {
      withGoogle()
    }
  }, [googleProfile])
  //=============================== End withGoogle


  const getToken = async () => {
    try {
      const response = await apiRequest({
        route: `me`,
        method: 'GET',
        headers: { Authorization: `Bearer ${localStorage.getItem('auth_user_token')}` },
      })

      if (response.status === 200) {
        localStorage.setItem('auth_user', JSON.stringify(response.data))
        refreshPage()
      }

    } catch (error) {
      handleElse()
    }
  }

  useEffect(() => {
    if (auth_user_token) {
      getToken()
    }
  }, [isLogin])

  return (
    <>
      <div className={`d-flex-end ${windowWidth > 578 ? 'container pt-1' : 'auth-container-max-w pt-1'}`}>
        <form className='d-flex-end'>
          <div className="row d-flex-end max-sm-w mt-4">

            {/* Start Google */}
            <div className="col-12 d-flex-center">
              <div className="with-google d-flex-center">
                <div className="with-google-logo">
                  <LoginSocialGoogle
                    client_id={REACT_APP_GG_APP_ID || ''}
                    scope="openid profile email"
                    discoveryDocs="claims_supported"
                    access_type="offline"
                    onResolve={async ({ provider, data }: IResolveParams) => {
                      try {
                        setGoogleProvider(provider)
                        setGoogleProfile(data)
                      } catch (error) {
                      }
                    }}
                    onReject={async (err) => {
                      try {
                      } catch (error) {
                      }
                    }}
                  >
                    <span className='bm-btn' ref={withGoogleRef}><GoogleLoginButton className='google-btn login-icon' /></span>
                  </LoginSocialGoogle>
                </div>

                <div className="with-google-text ff-1 fw-600 c-pointer" onClick={startWithGoogle} >
                  تسجيل الدخول باستخدام حساب
                </div>
              </div>

            </div>
            {/* End Google */}
          </div>
        </form>

      </div>
    </>
  )
}

export default Login