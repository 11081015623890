import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async';
import { contexts } from '../../helpers/Index';
import AOS from 'aos';
import PostsList from '../../components/PostsList/PostsList';
import LeftSide from '../../components/LeftSide/LeftSide';
import Layout from '../../layouts/Layout';
import 'aos/dist/aos.css';
import './Home.css'


const Home: React.FC = () => {
    const { windowWidth } = contexts.useWindowWidthContext()

    useEffect(() => {
        AOS.init();
    }, [])


    return (

        <Layout>
            {/* <Helmet>
                <title>Nas News - جريدة ناس نيوز الإلكترونية</title>
            </Helmet> */}
            <Helmet>
                {/* Standard Meta Tags */}
                <title>Nas News - جريدة ناس نيوز الإلكترونية</title>
                <meta name="description" content="Nas News - جريدة ناس نيوز الإلكترونية: مشروع إعلامي تربوي وحقوقي." />
                <meta name="keywords" content="جريدة ناس نيوز الإلكترونية, حقوق الإنسان المغرب, أخبار حقوقية مغربية, صحافة حقوق الإنسان, أول جريدة حقوقية بالمغرب, أخبار المغرب العاجلة, مقالات حقوق الإنسان, الدفاع عن الحقوق بالمغرب, تغطية إخبارية حقوقية, أخبار مغربية مستقلة, الصحافة الحقوقية الإلكترونية, مراقبة حقوق الإنسان المغرب, تقارير حقوق الإنسان المغرب, منبر حريات المغرب, النشر الحقوقي الإلكتروني بالمغرب"/>
                {/* Open Graph Meta Tags */}
                <meta property="og:title" content="Nas News - جريدة ناس نيوز الإلكترونية" />
                <meta property="og:description" content="مشروع إعلامي تربوي وحقوقي يهدف لتقديم محتوى هادف ومتميز." />
                <meta property="og:image" content="%PUBLIC_URL%/media/logo/nasnews-logo.png" />
                <meta property="og:url" content="https://nasnews.ma/" />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="ar_AR" />
            </Helmet>
            <div className="container mt-4 hvh-100">
                <div className="row">
                    {windowWidth > 1200 ?
                        <LeftSide />
                        :
                        null
                    }

                    <div className="col-xl-8 col-12 right-side">
                        <PostsList />
                    </div>

                    {windowWidth < 1200 ?
                        <LeftSide />
                        :
                        null
                    }
                </div>
            </div>
        </Layout>
    )
}

export default Home;