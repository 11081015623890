import React from 'react';
import {
  WhatsappShareButton,
  WhatsappIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  PinterestShareButton,
  PinterestIcon,
} from 'react-share';
import { IoMdShareAlt } from 'react-icons/io'
import { apiUrl, blogImgUrl, frontUrl } from '../../helpers/Helpers';
import { PostProps } from '../../helpers/Props';

interface ShareButtonsProps {
  post: PostProps;
}

const ShareButtons: React.FC<ShareButtonsProps> = ({ post }) => {
  const ur = 'https://dev.nasnews.ma'
  const itemUrl = `${frontUrl}/post/${post.slug}`

  const lin = 'https://api.whatsapp.com/send?text='

  return (
    <p className='d-flex align-items-center'>
      <span className='me-3 share-btn-text d-none'>
        <IoMdShareAlt className='share-btn' />
        Partager:
      </span>

      {/* <div
        className="ss-box ss-responsive ss-circle me-2 pe-1 "
        data-ss-content="true"
        data-ss-social="whatsapp"
        data-ss-link={itemUrl}
      ></div> */}

      <div className="shareon me-2 pe-1">
        {/* <a className="facebook"></a>
        <a className="linkedin" ></a>
        <a className="pinterest"
          data-text={post.title}
          data-media={`${blogImgUrl}/${post?.image}`}
          href={`https://pinterest.com/pin/create/button/?url=${itemUrl}`}
          rel="noopener noreferrer"
          target="_blank"
        ></a>
        <a className="telegram"></a>
        <a className="twitter"></a> */}
        <a className="whatsapp radius-20px" href={`${lin}${itemUrl}`}></a>
      </div>

      <FacebookShareButton
        className='share-icon me-3'
        url={itemUrl}
        title={post.title}
      >
        <FacebookIcon size={37} round />
      </FacebookShareButton>

      <TwitterShareButton
        className='share-icon me-3'
        url={itemUrl}
        title={post.title}
      >
        <TwitterIcon size={37} round />
      </TwitterShareButton>

      <PinterestShareButton
        url={itemUrl}
        media={`${blogImgUrl}/${post?.image}`}
        className='share-icon'
        title={post.title}
      >
        <PinterestIcon size={37} round />
      </PinterestShareButton>

      <span>&nbsp;</span>
    </p>
  );
};

export default ShareButtons;