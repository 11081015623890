import { FC } from 'react';
import { Link } from 'react-router-dom';
import { MDBFooter, MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import Icons from '../../helpers/IconsHelper';
import { SocialMediaLink } from '../../helpers/Helpers';
import { contexts, iconNasnews } from '../../helpers/Index';
import './FooterStyle.css'

const Footer: FC = () => {

  const { windowWidth } = contexts.useWindowWidthContext()

  return (
    <>
      <MDBFooter className='text-center text-lg-start bg-clr-footer ff-1 mt-4 clr-light ff-600 fixedbottom'>
        <section className=''>
          <MDBContainer className='text-center text-md-start mt-2 pt-2'>
            <MDBRow className='mt-3 d-flex-center'>

              <MDBCol md='4' lg='3' xl='5' className='mx-auto mb-md-0 mb-4'>
                <div className={`social-media-content d-flex-${windowWidth < 578 ? 'center' : 'start'}`}>
                  <SocialMediaLink key={0} to='https://www.instagram.com/' icon={<Icons.RiInstagramFill className='clr-light fs-17px icon m-0' />} />
                  <SocialMediaLink key={1} to='https://www.youtube.com/' icon={<Icons.FaYoutube className='clr-light fs-17px icon m-0' />} />
                  <SocialMediaLink key={2} to='https://www.twitter.com/' icon={<Icons.FaTwitter className='clr-light fs-17px icon m-0' />} />
                  <SocialMediaLink key={3} to='https://www.facebook.com' icon={<Icons.FaFacebookF className='clr-light fs-17px icon m-0' />} />
                </div>
              </MDBCol>

              <MDBCol md='3' lg='4' xl='5' className={`mxauto mb-4 text-${windowWidth < 578 ? 'center' : 'end'}`}>
                <h6 className='text-uppercase clr-light ff-1 fw-bold mb-4 dir-rtl'>
                  من نحن
                </h6>
                <p className=''>
                  أول جريدة الكترونية حقوقية بالمغرب
                </p>
              </MDBCol>

              <MDBCol md='1' lg='1' xl='2' className={`mx-auto ms-0 mb-4 text-${windowWidth < 578 ? 'center' : 'end'}`}>
                <img src={iconNasnews} alt='nas news' className='w-80px' />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

        <div className='text-center p-4 copy-right-bg'>
          {new Date().getFullYear()} © جميع الحقوق محفوظة
          <Link className='text-reset fw-bold ps-1' to='/'>
            nasnews
          </Link>
        </div>
      </MDBFooter>
    </>
  );
};

export default Footer;